import ConditionalSkeleton from '@/components/animations/conditionalSkeleton';
import CreateRoomButton from '@/components/createRoomButton';
import ChatSideBarSkeleton from '@/components/skeletons/chatSideBarSkeleton';
import { SegmentControlStyles } from '@/components/swipeableTabViews';
import { axiosClient } from '@/data/axios';
import { db, ENV, Room, useRooms } from '@/firebase/firestore';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import useUserInfo from '@/providers/auth/useUserInfo';
import { RoomType } from '@/types/chatRoom';
import { isProduction } from '@/utils/config';
import { Box, Stack, Tab, Tabs, Theme, Typography, useMediaQuery } from '@mui/material';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useAsyncEffect } from 'rooks';
import RoomItem from './roomItem';

export default function RoomSideBar( { room }: { room?: Room } ) {
	const { sessionCompanyId } = useCompanyId();
	const { staff } = useUserInfo();
	const router = useRouter();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	const internalActive = staff?.company?.chatSettings?.internal;
	const externalActive = staff?.company?.chatSettings?.external;
	
	const [ type, setType ] = useState<RoomType | undefined>(
		room ? room.type : externalActive || internalActive
			? RoomType.INTERNAL
			: RoomType.EXTERNAL,
	);
	const { rooms, loading } = useRooms( sessionCompanyId, type );
	const { rooms: aiRooms, loading: loadingAiRooms } = useRooms( sessionCompanyId, RoomType.AI, false, { limit: 10 } );
	const roomsByType = type === RoomType.AI ? aiRooms : rooms?.filter( ( room ) => room.type === type );
	
	async function createRoomAndRedirect() {
		const { data } = await axiosClient.post( '/api/user/chat/AI/createRoom' );
		return router.push( `/dashboard/chat/${data.roomId}`, undefined, { shallow: true } );
	}
	
	useAsyncEffect( async () => {
		if ( isProduction ) return;
		if ( loadingAiRooms || router.query.id || type !== RoomType.AI ) return;
		const lastRoom = roomsByType?.[ 0 ];
		if ( !lastRoom ) return createRoomAndRedirect();
		const q = query(
			collection( db, 'envs', ENV, 'rooms', lastRoom.id, 'messages' ),
			orderBy( 'createdAt', 'desc' ),
			limit( 1 ),
		);
		const querySnapshot = await getDocs( q );
		if ( querySnapshot.empty ) return router.push( `/dashboard/chat/${lastRoom.id}`, undefined, { shallow: true } );
		return createRoomAndRedirect();
	}, [ type, loadingAiRooms ] );
	
	return (
		<Stack height='100%' width='100%'>
			{!isMobile && <CreateRoomButton type={type}/>}
			{internalActive && externalActive && (
				<Box
					sx={{
						...SegmentControlStyles,
						width: 'max-content',
						mx   : 'auto',
					}}>
					<Tabs
						value={type}
						sx={{
							'&&& .MuiButtonBase-root': {
								flex    : 1,
								minWidth: 'unset',
								px      : 3,
							},
						}}
						onChange={( e, type ) => setType( type )}>
						<Tab value={RoomType.EXTERNAL} label='External'/>
						<Tab value={RoomType.INTERNAL} label='Internal'/>
						{/*{!isProduction && <Tab value={RoomType.AI} label='AI'/>}*/}
					</Tabs>
				</Box>
			)}
			<ConditionalSkeleton
				skeleton={<ChatSideBarSkeleton/>}
				isLoading={loading}
				boxProps={{ sx: { flex: 1, overflowY: 'auto' } }}>
				{!isEmpty( roomsByType )
					? roomsByType!.map( ( room ) => (
						<RoomItem
							key={room.id}
							room={room}
						/>
					) ) : (
						<Box sx={{ textAlign: 'center', mt: 5 }}>
							<Typography>Create a room to get started!</Typography>
						</Box>
					)}
			</ConditionalSkeleton>
		</Stack>
	);
}
