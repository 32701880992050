import PageLinkComponent from '@/components/page/linkComponent';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Button } from '@mui/material';
import { useAtom } from 'jotai/index';
import { Banner } from '.';
import safeDateFns from '../../../../helpers/safeFormat';
import useGetDeviceInfo from '../../../../hooks/useGetDeviceInfo';
import { dismissibleBannerAtoms } from '../../../../utils/atoms';
import { useRouter } from 'next/router';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
export default function useScheduleTrainingBanner(): Banner {
	const { user, staff } = useUserInfo();
	const { isCloverDevice } = useGetDeviceInfo();
	const router = useRouter();
	
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	
	const isPublic = router.route.split( '/' )[ 1 ] === 'p';
	const isClient = router.route.split( '/' )[ 1 ] === 'client';
	
	return {
		id  : 'schedule-training-banner',
		show: staff && !isPublic && !isClient && !globalBanners.hideScheduleTrainingBanner
			&& ( safeDateFns.isSameDay( staff?.company?.createdAt, new Date() ) || staff?.company?.companyCountMetric?.invoiceCount < 3 )
			&& !isCloverDevice
			&& Boolean( staff ),
		icon    : <EventNoteRoundedIcon/>,
		title   : `Welcome to Invoiss! ${user?.firstName}`,
		subtitle: 'Explore our platform and schedule a training session with one of our specialists to discover the capabilities of Invoiss in just 30 minutes.',
		color   : 'primary',
		onClose : () => setGlobalBanners( ( prev ) => ( { ...prev, hideScheduleTrainingBanner: true } ) ),
		actions : [
			<Button
				key='schedule'
				color='primary'
				variant='contained'
				component={PageLinkComponent}
				target='_blank'
				href='https://calendly.com/invoiss/invoiss-introduction'>
				Schedule a training session
			</Button>,
		],
	};
}

