import PublicStoreLayout from '@/pages/p/store/_layout';
import PartnerLayout from '@/pages/partner/_layout';
import PartnerDashboardLayout from '@/pages/partner/dashboard/_layout';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { MainLayout } from './pages/_layout';
import AdminLayout from './pages/admin/_layout';
import { ClientLayout } from './pages/client/_layout';
import { DashboardLayout } from './pages/dashboard/_layout';
import ChatLayout from './pages/dashboard/chat/_layout';
import SettingsLayout from './pages/dashboard/settings/_layout';
import StaticThemeProvider from './providers/staticTheme';
import EmailVerificationWraper from './baseline/navigation/desktop/banners/emailVerificationBanner';

export default function Layout( { children } ) {
	const router = useRouter();
	const route = router.route.split( '/' );
	
	switch ( route[ 1 ] ) {
		case 'p':
		case 'settings':
		case 'welcome':
			if ( route[ 1 ] === 'p' && route[ 3 ] === 'store' ) return <PublicStoreLayout>{children}</PublicStoreLayout>;
			return <MainLayout>{children}</MainLayout>;
		case 'client':
			return (
				<MainLayout>
					<ClientLayout>
						{route[ 2 ] === 'chat'
							? <ChatLayout clientView>{children}</ChatLayout>
							: children}
					</ClientLayout>
				</MainLayout>
			);
		case 'driver':
			return <MainLayout>{children}</MainLayout>;
		case 'dashboard':
			return (
				<EmailVerificationWraper>
					<MainLayout applySubscription>
						<DashboardLayout>
							{route[ 2 ] === 'settings'
								? <SettingsLayout>{children}</SettingsLayout>
								: route[ 2 ] === 'chat'
									? <ChatLayout>{children}</ChatLayout>
									: children}
						</DashboardLayout>
					</MainLayout>
				</EmailVerificationWraper>
			);
		case 'partner':
			return (
				<EmailVerificationWraper>
					<PartnerLayout>
						<PartnerDashboardLayout>
							{children}
						</PartnerDashboardLayout>
					</PartnerLayout>
				</EmailVerificationWraper>
			);
		case 'admin':
			return <MainLayout applySubscription><AdminLayout>{children}</AdminLayout></MainLayout>;
		
		default:
			return <StaticThemeProvider>{children}</StaticThemeProvider>;
	}
}
