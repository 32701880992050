export interface IOpenSearchResponse<T> {
	took: number
	timed_out: boolean
	_shards: {
		total: number
		successful: number
		skipped: number
		failed: number
	}
	hits: {
		total: {
			value: number
			relation: string
		}
		max_score: number
		hits: IOpenSearchResult<T>[]
	}
}

export interface IOpenSearchResult<T> {
	_index: string
	_type: string
	_id: string
	_score: number
	_source: T
}

export interface INotification {
	id?: string
	userId: string
	type: NotificationType
	title: string
	content: string
	companyId?: string
}

export interface IEmail {
	id?: string
	userId: string
	messageId: string
	companyId?: string
	time?: string
}

export enum NotificationType {
	SYSTEM = 'SYSTEM',
	EMAIL = 'EMAIL',
	PAYMENT = 'PAYMENTS',
}