import HelpButton from '@/baseline/navigation/desktop/helpButton';
import LanguageButton from '@/baseline/navigation/desktop/languageButton';
import ProfileButton from '@/baseline/navigation/desktop/profileButton';
import { usePartner } from '@/hooks/usePartnerFetch';
import useUserInfo from '@/providers/auth/useUserInfo';
import {
	Storefront as StorefrontIcon,
} from '@mui/icons-material';
import { AppBar, Avatar, Box, Container, ListItemAvatar, ListItemButton, ListItemText, Stack, Theme, useMediaQuery } from '@mui/material';
import { capitalize } from 'lodash-es';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useWindowSize } from 'react-use';
import { useAsyncEffect } from 'rooks';
export default function PartnerLayout( { children }: { children: ReactNode } ) {
	const { height } = useWindowSize();
	const { user } = useUserInfo();
	const router = useRouter();
	const { partner } = usePartner();
	const wide = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( 'sm' ) );
	
	useAsyncEffect( async () => {
		if ( !user?.salesAgent?.id ) await router.replace( '/welcome' );
	}, [ user ] );
	
	if ( !user?.salesAgent?.id ) return null;
	
	return (
		<Box
			pl='env(safe-area-inset-left)'
			pr='env(safe-area-inset-right)'
			minHeight={`min(calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)), ${height}px)`}
			sx={{
				bgcolor: 'background.default',
			}}>
			<AppBar position='static' color='inherit' sx={{ boxShadow: 0, bgcolor: 'transparent', border: 0 }}>
				<Container maxWidth='xl' sx={{ py: 1 }}>
					<Stack
						direction='row'
						alignItems='center'
						spacing={2}>
						<ListItemButton sx={{ borderRadius: 2, flexGrow: 0, p: 1, maxWidth: 500 }}>
							<ListItemAvatar sx={{ minWidth: 45 }}>
								<Avatar
									src={partner?.logo}
									sx={{
										width  : 35,
										height : 35,
										bgcolor: partner?.logo ? '#ffffff' : undefined,
										img    : { objectFit: 'contain' },
									}}>
									<StorefrontIcon/>
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={capitalize( partner?.name )}
								primaryTypographyProps={{
									variant: 'h5',
									sx     : {
										whiteSpace  : 'nowrap',
										overflow    : 'hidden',
										textOverflow: 'ellipsis',
									},
								}}
							/>
						</ListItemButton>
						<Box display='flex' flexGrow={1}/>
						<Stack
							direction='row'
							alignItems='center'
							spacing={2}>
							<HelpButton/>
							{wide && <LanguageButton/>}
							<ProfileButton
								removeDriverMode
								removeClientMode
								removeCompanyMode
								profileRoute='/partner/settings'
							/>
						</Stack>
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Box>
	);
}

