import SubscriptionModal from '@/baseline/subscription';
import { getSubscription } from '@/baseline/subscription/getSubscription';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import { Button, Chip, Typography } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import { useAtom } from 'jotai/index';
import { Banner } from '.';
import useUserInfo from '@/providers/auth/useUserInfo';
import TryRoundedIcon from '@mui/icons-material/TryRounded';
export default function useSubscriptionBanner(): Banner {
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	const { company } = useCompany();
	const { showModal } = useModal();
	const { staff } = useUserInfo();
	
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	
	return {
		id      : 'subscription-banner',
		color   : 'amethyst',
		onClose : () => setGlobalBanners( ( prev ) => ( { ...prev, hideSubscriptionBanner: true } ) ),
		show    : staff && !globalBanners.hideSubscriptionBanner && subscription?.tier.name === 'Trial',
		icon    : <TryRoundedIcon/>,
		title   : 'Lets upgrade your plan',
		subtitle: (
			<Typography color='text.secondary'>
				You are currently subscribed to the {subscription?.tier.name} plan. Your trial for some features will
				expire in
				<Chip
					label={(
						<Typography>
							{differenceInCalendarDays( new Date( subscription?.endDate ), new Date() )} Days
						</Typography>
					)}
					variant='alpha'
					color='warning'
					sx={{ mx: .5 }}
				/> Please refer to our plan page for more details.
			</Typography>
		),
		actions: [
			<Button
				key='view-plans'
				variant='contained'
				color='amethyst'
				onClick={() => showModal( SubscriptionModal, { variant: 'fullPageDialog' } )}>
				View plans
			</Button>,
		],
	};
}

