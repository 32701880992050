import CustomCheckBox from '@/components/customCheckBox';
import Form from '@/components/form';
import { ModalFormWrapper } from '@/components/form/modal';
import { axiosClient } from '@/data/axios';
import { Room } from '@/firebase/firestore';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { RoomType } from '@/types/chatRoom';
import { staffsMapAtom } from '@/utils/atoms';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import { map, omit } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommerceSelect from 'src/pages/formSelects/commerceSelect';
import ClientSelect from '../../formSelects/clientSelect';
import StaffSelect from '../../formSelects/staffSelect';

export default function ModalRoomForm( {
	room,
	onSubmit,
	clientView,
}: {
	room?: Room,
	onSubmit?: ( room: Room ) => void,
	clientView?: boolean
} ) {
	const { closeModal } = useModalControls();
	const { sessionCompanyId } = useCompanyId();
	const { user, staff } = useUserInfo();
	const { t } = useTranslation();
	const [ staffsAtom ] = useAtom( staffsMapAtom );
	const [ isExternal, setIsExternal ] = useState( true );
	
	if ( clientView ) {
		return <Typography variant='h6' color='text.secondary'>You are not allowed to create a room</Typography>;
	}
	
	const staffs = map( room?.staffs, ( staff ) => staffsAtom[ staff ] );
	
	return (
		<Form
			initialValues={{
				staffs: room ? staffs : [ {
					...staff,
					user: omit( user, 'staffs' ),
				} ],
				client    : null,
				commerceId: null,
			}}
			onSubmit={async ( { client, ...values } ) => {
				const roomType = isExternal ? RoomType.EXTERNAL : RoomType.INTERNAL;
				if ( room ) {
					const { data } = await axiosClient.post( '/api/user/chat/updateRoom', {
						roomId   : room.id,
						companyId: sessionCompanyId,
						staffs   : values.staffs.map( ( staff ) => staff.email || staff.user?.email ),
						client   : room ? room.client : client.email,
					} );
					onSubmit?.( data?.roomId );
				} else {
					const { data } = await axiosClient.post( '/api/user/chat/createRoom', {
						type      : roomType,
						commerceId: values.commerceId,
						companyId : sessionCompanyId,
						staffs    : values.staffs.map( ( staff ) => staff.email || staff.user?.email ),
						client    : isExternal ? room ? room.client : client.email : null,
						clientView: false,
					} );
					onSubmit?.( data?.roomId );
				}
				
				closeModal();
			}}>
			{( formik ) => (
				<ModalFormWrapper
					name='Room'
					saveButtonText={room ? t( 'common:update-room' ) : t( 'common:create-room' )}
					saveButtonProps={{
						disabled: room
							? formik.values.staffs?.length < 1
							: isExternal
								? !formik.values.client
								: formik.values.staffs?.length < 2,
					}}>
					<Grid container spacing={2}>
						{!room && (
							<Grid item xs={12}>
								<Stack direction='row' alignItems='center'>
									<CustomCheckBox
										checked={isExternal}
										onClick={async ( e ) => setIsExternal( !isExternal )}
									/>
									Chat with a client
								</Stack>
							</Grid>
						)}
						{isExternal && !room && (
							<Grid item xs={12}>
								<ClientSelect
									fullWidth
									name='client'
									disabled={Boolean( room && formik.values.client )}
									textFieldProps={{ helperText: 'Clients can only be added to a room once' }}
								/>
							</Grid>
						)}
						{isExternal && !room && formik.values.client && (
							<Grid item xs={12}>
								<CommerceSelect
									fullWidth
									name='commerce'
									client={formik.values?.client?.id}
									disabled={Boolean( room && formik.values.commerceId )}
									textFieldProps={{ helperText: 'The staff who placed the order will be added to the chat.' }}
									onChange={( e, value ) => {
										if ( !value ) {
											formik.setFieldValue( 'staffs', [ { ...staff, user: omit( user, 'staffs' ) } ] );
											formik.setFieldValue( 'commerceId', null );
										} else {
											const existingStaffIds = new Set( formik.values.staffs.map( ( s ) => s.id ) );
											if ( !existingStaffIds.has( value.staff?.id ) ) {
												formik.setFieldValue( 'staffs', [ ...formik.values.staffs, value.staff ] );
											}
											formik.setFieldValue( 'commerceId', value?.id );
										}
									}}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<StaffSelect
								multiple
								disableCloseOnSelect
								disableClearable
								name='staffs'
								variables={{ options: { filter: { id: { $nin: [ staff.id ] }, user: { $ne: null } } } }}
								renderTags={( value: any, getTagProps ) => value.map( ( v, index ) => {
									if ( !v ) return null;
									return (
										<Chip
											key={index}
											{...getTagProps( { index } )}
											disabled={v?.id === staff.id}
											label={v?.user?.firstName || v?.firstName}
											onDelete={v?.id === staff.id
												? undefined
												: () => formik.setFieldValue( 'staffs', formik.values.staffs.filter( ( staff ) => staff?.id !== v?.id ) )}
										/>
									);
								} )}
								textFieldProps={{
									placeholder: 'Search by name...',
								}}
							/>
						</Grid>
					</Grid>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
