import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardActions, Collapse, IconButton, Paper, Stack, Typography } from '@mui/material';
import { type CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback, useState } from 'react';

interface Props extends CustomContentProps {
	cloverQuantity: number,
	invoissQuantity: number,
	lineItem: {
		id: string,
		name?: string,
		sku?: string,
		code?: string
	}
}

const CloverStockMismatch = forwardRef<HTMLDivElement, Props>(
	( { id, lineItem, cloverQuantity, invoissQuantity, message }, ref ) => {
		const { closeSnackbar } = useSnackbar();
		const [ expanded, setExpanded ] = useState( false );
		
		const handleExpandClick = useCallback( () => {
			setExpanded( ( oldExpanded ) => !oldExpanded );
		}, [] );
		
		const handleDismiss = useCallback( () => {
			closeSnackbar( id );
		}, [ id, closeSnackbar ] );
		
		console.log( { lineItem, invoissQuantity, cloverQuantity } );
		
		return (
			<SnackbarContent ref={ref}>
				<Card style={{ backgroundColor: '#ff9800' }}>
					<CardActions>
						<Typography variant='body2'>
							{message}
						</Typography>
						<div>
							<Button
								aria-label='resolve'
								size='small'
								onClick={handleExpandClick}>
								Resolve
							</Button>
							<IconButton
								size='small'
								onClick={handleDismiss}>
								<CloseIcon fontSize='small'/>
							</IconButton>
						</div>
					</CardActions>
					<Collapse unmountOnExit in={expanded} timeout='auto'>
						<Stack>
							<Button
								aria-label='resolve'
								size='small'
								onClick={handleExpandClick}>
								Resolve
							</Button>
							<Button
								aria-label='resolve'
								size='small'
								onClick={handleExpandClick}>
								Resolve
							</Button>
						</Stack>
					</Collapse>
				</Card>
			</SnackbarContent>
		);
	},
);

export default CloverStockMismatch;
