import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Close as CloseIcon } from '@mui/icons-material';
import { Box, Container, IconButton, ListItem, ListItemIcon, ListItemText, Paper, Stack, Tooltip, alpha } from '@mui/material';
import { motion } from 'framer-motion';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import useCardConnectBanner from './cardConnectBanner';
import useCloverDeviceBanner from './cloverDeviceBanner';
import useScheduleTrainingBanner from './scheduleTrainingBanner';
import useSubscriptionBanner from './subscriptionBanner';
import useVopayBanner from './vopayBanner';
import useWidgetBanner from './widgetBanner';

export interface Banner {
	id: string,
	title: ReactNode,
	subtitle?: ReactNode,
	icon?: ReactNode,
	actions?: ReactNode[],
	onClose?: () => void,
	color: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | 'amethyst',
	show?: boolean
}

interface BannerState extends Banner {
	zIndex: number,
	offset: number,
	scale: number
}

export default function GlobalBanners() {

	const cardConnectBanner = useCardConnectBanner();
	const cloverDeviceBanner = useCloverDeviceBanner();
	const vopayBanner = useVopayBanner();
	const scheduleTrainingBanner = useScheduleTrainingBanner();
	const subscriptionBanner = useSubscriptionBanner();
	const widgetBanner = useWidgetBanner();
	
	const initialBanners = [
		cardConnectBanner,
		cloverDeviceBanner,
		vopayBanner,
		scheduleTrainingBanner,
		subscriptionBanner,
		widgetBanner,
	];

	const [ bannerOrder, setBannerOrder ] = useState<BannerState[]>(
		initialBanners.filter( ( banner ) => banner.show ).map( ( banner, index ) => ( {
			...banner,
			zIndex: initialBanners.length - index,
			offset: index * -6,
			scale : 1 - index * 0.02,
		} ) ),
	);

	// Update banners when their show state changes, but keep current order for visible ones
	useEffect( () => {
		const currentVisible = new Set( bannerOrder.map( ( b ) => b.id ) );
		const newBanners = initialBanners.filter( ( banner ) => banner.show );
		
		// Only update if we have different visible banners
		if ( newBanners.length !== currentVisible.size || newBanners.some( ( banner ) => !currentVisible.has( banner.id ) ) ) {
			
			setBannerOrder( newBanners.map( ( banner, index ) => ( {
				...banner,
				zIndex: newBanners.length - index,
				offset: index * -6,
				scale : 1 - index * 0.02,
			} ) ) );
		}
	}, [
		cardConnectBanner.show,
		cloverDeviceBanner.show,
		vopayBanner.show,
		scheduleTrainingBanner.show,
		subscriptionBanner.show,
		widgetBanner.show,
	] );

	const handleDismiss = ( bannerId: string ) => {
		// Find the banner and call its onClose function if it exists
		const banner = bannerOrder.find( ( b ) => b.id === bannerId );
		if ( banner?.onClose ) {
			banner.onClose();
		}
		
		setBannerOrder( ( prev ) => {
			const newOrder = prev.filter( ( b ) => b.id !== bannerId );
			// Recalculate positions for remaining banners
			return newOrder.map( ( banner, index ) => ( {
				...banner,
				zIndex: newOrder.length - index,
				offset: index * -6,
				scale : 1 - index * 0.02,
			} ) );
		} );
	};

	const handleNavigate = ( direction: 'left' | 'right' ) => {
		setBannerOrder( ( prev ) => {
			const newOrder = [ ...prev ];
			if ( direction === 'left' ) {
				const last = newOrder.pop();
				if ( last ) newOrder.unshift( last );
			} else {
				const first = newOrder.shift();
				if ( first ) newOrder.push( first );
			}
			return newOrder.map( ( banner, index ) => ( {
				...banner,
				zIndex: newOrder.length - index,
				offset: index * -6,
				scale : 1 - index * 0.02,
			} ) );
		} );
	};

	if ( !bannerOrder.length ) return null;

	return (
		<Box
			sx={{
				'bgcolor'  : 'transparent',
				'height'   : { xs: 130, lg: 110 },
				'position' : 'relative',
				'overflow' : 'hidden',
				'&::before': {
					content      : '""',
					position     : 'absolute',
					top          : 0,
					left         : 0,
					right        : 0,
					bottom       : 0,
					background   : ( theme ) => `linear-gradient(45deg, ${theme.palette.mode === 'light' ? 'rgba(255, 99, 132, 0.15)' : 'rgba(255, 99, 132, 0.08)'} 0%, ${theme.palette.mode === 'light' ? 'rgba(54, 162, 235, 0.15)' : 'rgba(54, 162, 235, 0.08)'} 50%, ${theme.palette.mode === 'light' ? 'rgba(153, 102, 255, 0.15)' : 'rgba(153, 102, 255, 0.08)'} 100%)`,
					opacity      : 1,
					zIndex       : 1,
					pointerEvents: 'none',
				},
				'&::after': {
					content      : '""',
					position     : 'absolute',
					bottom       : 0,
					left         : 0,
					right        : 0,
					height       : '40%',
					background   : ( theme ) => `linear-gradient(to bottom, ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0)'} 0%, ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.05)'} 100%)`,
					pointerEvents: 'none',
					zIndex       : 2,
				},
			}}>
			<Container maxWidth='xl' sx={{ height: '100%', pt: 3, position: 'relative', zIndex: 3, bgcolor: 'transparent' }}>
				<Box sx={{ position: 'relative', bgcolor: 'red' }}>
					{bannerOrder.map( ( banner ) => (
						<motion.div
							key={banner.id}
							initial={{
								y      : banner.offset,
								zIndex : banner.zIndex,
								scale  : banner.scale,
								opacity: banner.zIndex === bannerOrder.length ? 1 : 0.3 + banner.zIndex * 0.2,
							}}
							animate={{
								y      : banner.offset,
								zIndex : banner.zIndex,
								scale  : banner.scale,
								opacity: banner.zIndex === bannerOrder.length ? 1 : 0.3 + banner.zIndex * 0.2,
							}}
							transition={{
								type     : 'spring',
								stiffness: 200,
								damping  : 25,
								mass     : 0.5,
							}}
							style={{
								position: 'absolute',
								left    : 0,
								right   : 0,
							}}>
							<Paper sx={{ boxShadow: 'none' }}>
								<Paper
									sx={{
										boxShadow  : 'none',
										bgcolor    : ( theme ) => alpha( theme.palette[ banner.color ]?.main, 0.1 ),
										border     : 1,
										borderColor: ( theme ) => alpha( theme.palette[ banner.color ]?.main, 0.5 ),
										...banner.zIndex !== bannerOrder.length && {
											height  : '72px',
											overflow: 'hidden',
										},
									}}>
									<ListItem
										sx={{
											...banner.zIndex !== bannerOrder.length && {
												height: '100%',
											},
										}}>
										<ListItemIcon
											sx={{
												minWidth      : 40,
												height        : 40,
												mr            : 2,
												display       : 'flex',
												alignItems    : 'center',
												justifyContent: 'center',
												borderRadius  : 1,
												bgcolor       : 'action.selected',
												svg           : { color: `${banner.color}.main` },
											}}>
											{banner.icon}
										</ListItemIcon>
										<ListItemText
											primary={banner.title}
											secondary={banner.subtitle}
											primaryTypographyProps={{
												fontWeight: 500,
												color     : `${banner.color}.main`,
												fontSize  : '1.5rem !important',
											}}
										/>
										<Stack direction='row' spacing={1}>
											{banner.actions && banner.actions.length > 0 && (
												<Fragment>
													{banner.actions}
												</Fragment>
											)}
											<Tooltip title='Dismiss'>
												<IconButton
													edge='end'
													size='small'
													onClick={() => handleDismiss( banner.id )}>
													<CloseIcon fontSize='small'/>
												</IconButton>
											</Tooltip>
										</Stack>
									</ListItem>
								</Paper>
							</Paper>
						</motion.div>
					) )}

					{/* Navigation arrows */}
					{bannerOrder.length > 1 && (
						<Fragment>
							<IconButton
								size='small'
								sx={{
									position : 'absolute',
									left     : -20,
									top      : 'calc(50% + 36px)',
									color    : 'text.secondary',
									bgcolor  : 'background.paper',
									boxShadow: 1,
									zIndex   : 4,
									transform: 'translateY(-50%)',
								}}
								onClick={() => handleNavigate( 'left' )}>
								<ChevronLeftIcon/>
							</IconButton>
							<IconButton
								size='small'
								sx={{
									position : 'absolute',
									right    : -20,
									top      : 'calc(50% + 36px)',
									color    : 'text.secondary',
									bgcolor  : 'background.paper',
									boxShadow: 1,
									zIndex   : 4,
									transform: 'translateY(-50%)',
								}}
								onClick={() => handleNavigate( 'right' )}>
								<ChevronRightIcon/>
							</IconButton>
						</Fragment>
					)}
				</Box>
			</Container>
		</Box>
	);
}
