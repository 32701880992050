import PageLinkComponent from '@/components/page/linkComponent';
import IframeButton from '@/pages/dashboard/main/iframeButton';
import SettingsButton from '@/pages/dashboard/main/settingsButton';
import useGetPartnerDashboardTabs from '@/pages/partner/dashboard/dashboardTabs';
import useUserInfo from '@/providers/auth/useUserInfo';
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Button, Container, IconButton, Stack, Theme, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { Fragment, ReactNode, useState } from 'react';
import { InsertLink as InsertLinkIcon } from '@mui/icons-material';
import EmailVerificationWraper from '@/baseline/navigation/desktop/banners/emailVerificationBanner';

export default function PartnerDashboardLayout( { children }: { children: ReactNode } ) {
	const tabs = useGetPartnerDashboardTabs();
	const router = useRouter();
	const theme = useTheme();
	const { user } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'md' ) );
	const [ value, setValue ] = useState( 0 );
   
	const filteredTabs = tabs?.filter( ( tab ) => [ 'Fee Calculator', 'Accounts', 'Sales Agents', 'Settings' ].includes( tab.name ) );
   
	if ( isMobile ) {
		return (
			<Fragment>
				<Box sx={{ pb: 7 }}>
					{children}
				</Box>
				<BottomNavigation
					showLabels
					value={value}
					sx={{
						'width'                             : '100%',
						'position'                          : 'fixed',
						'bottom'                            : 0,
						'borderTop'                         : 1,
						'borderColor'                       : 'divider',
						'bgcolor'                           : 'background.paper',
						'zIndex'                            : theme.zIndex.appBar,
						'pb'                                : 2,
						'pt'                                : 1,
						'& .MuiBottomNavigationAction-label': {
							fontSize  : '1.2rem !important',
							whiteSpace: 'nowrap',
						},
					}}
					onChange={( event, newValue ) => {
						setValue( newValue );
						const tab = filteredTabs[ newValue ];
						if ( tab ) {
							router.push( tab.href );
						}
					}}>
					{filteredTabs.map( ( tab, index ) => {
						const Icon = tab.Icon;
						return (
							<BottomNavigationAction
								key={index}
								label={tab.name}
								icon={Icon ? <Icon/> : null}
								value={index}
								sx={{
									minWidth: 'auto',
									px      : 1,
								}}
							/>
						);
					} )}
				</BottomNavigation>
			</Fragment>
		);
	}
   
	return (
		<Fragment>
			<AppBar
				color='inherit'
				position='static'
				sx={{
					border      : 0,
					borderBottom: 1,
					borderColor : 'divider',
					pt          : 1,
					pb          : 1,
					bgcolor     : 'transparent',
					boxShadow   : 'none !important',
               
				}}>
				<Container maxWidth='xl'>
					<Stack spacing={1} direction='row' sx={{ display: 'flex', flexGrow: 1 }}>
						{tabs?.filter( ( tab ) => tab.name !== 'Settings' ).map( ( tab, index ) => {
							const selectedRoute = router.pathname === tab.href || router.pathname.startsWith( tab.href ) && tab.href !== '/partner/dashboard';
							return (
								<Button
									key={index}
									color={selectedRoute ? 'primary' : 'alpha'}
									className='AppbarButton'
									sx={{
										'height'      : 27,
										'boxShadow'   : 'none !important',
										'border'      : '0px !important',
										'transition'  : '.4s all',
										'borderRadius': '15px !important',
										'color'       : selectedRoute ? '#ffffff !important' : undefined,
										'bgcolor'     : selectedRoute
											? `${theme.palette.primary.main} !important`
											: 'transparent !important',
										':hover': {
											bgcolor: selectedRoute
												? `${theme.palette.primary.main} !important`
												: `${theme.palette.divider} !important`,
										},
									}}
									component={PageLinkComponent}
									href={tab.href}>
									{tab.name}
								</Button>
							);
						} )}
						<Box display='flex' flexGrow={1}/>
						{user?.salesAgent?.isOwner && <IframeButton/>}
						{user?.salesAgent?.partner?.id && (
							<Tooltip title='Copy Customer Sign Up Link' placement='bottom'>
								<IconButton
									onClick={() => {
										navigator.clipboard.writeText( `${process.env.NEXT_PUBLIC_SITE_URL}/signup?c_partnerId=${user?.salesAgent?.partner?.id}` );
										enqueueSnackbar( 'Link copied to clipboard.', { variant: 'success' } );
									}}>
									<InsertLinkIcon/>
								</IconButton>
							</Tooltip>
						)}
						<SettingsButton title='Profile & Company Settings' href='/partner/settings'/>
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Fragment>
	);
}
