import { blurryBackground } from '@/components/elevationScroll';
import Loading from '@/components/loading';
import { mutateGraphQL } from '@/data/apollo';
import { StaffTableWriteAllDataGQL } from '@/data/staffTableWriteGQL';
import { StaffTableWrite_DataMutation, StaffTableWrite_DataMutationVariables } from '@/generated/graphql';
import { StaffTableWrite } from '@/graphql/staff.graphql';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import RequirePinCodeModal from '@/pages/dashboard/settings/security/requirePinCodeModal';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { MutationStaffWriteArgs } from '@/types/schema';
import { Lock as LockIcon } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export default function AppLockButton() {
	const { staff } = useUserInfo();
	const { showModal, closeModal } = useModal();
	const queryClient = useQueryClient();
	
	const [ loading, setLoading ] = useState( false );
	
	useEffect( () => {
		if ( !staff || !staff?.company?.hasPinCode || !staff?.metadata?.enableAppLock || !staff?.metadata?.lockApp ) return;
		showModal( RequirePinCodeModal, {
			maxWidth         : 'xs',
			onClose          : ( event, reason ) => stayOpen( event, reason ),
			BackdropComponent: () => (
				<Box
					sx={{
						...blurryBackground(),
						position: 'absolute',
						top     : 0,
						left    : 0,
						right   : 0,
						bottom  : 0,
					}}
				/>
			),
		}, {
			onSubmit: async ( isApproved ) => {
				if ( isApproved ) {
					await mutateGraphQL<StaffTableWrite_DataMutationVariables, StaffTableWrite_DataMutation>( {
						mutation : StaffTableWriteAllDataGQL,
						variables: {
							input: {
								metadata: {
									lockApp: false,
								},
							},
						},
					} );
					await queryClient.invalidateQueries( [ 'user' ] );
					closeModal();
				}
			},
			disableCancel: true,
		} );
	}, [ staff?.company?.hasPinCode, staff?.metadata.enableAppLock, staff?.metadata?.lockApp ] );
	
	if ( !staff || !staff?.company?.hasPinCode || !staff?.metadata?.enableAppLock ) return;
	
	return (
		<Tooltip title='Lock App'>
			<IconButton
				onClick={async () => {
					try {
						setLoading( true );
						await mutateGraphQL<StaffTableWrite_DataMutationVariables, StaffTableWrite_DataMutation>( {
							mutation : StaffTableWriteAllDataGQL,
							variables: {
								input: {
									metadata: {
										lockApp: true,
									},
								},
							},
						} );
						await queryClient.invalidateQueries( [ 'user' ] );
						setLoading( false );
					} catch ( e ) {
						console.error( e );
					} finally {
						setLoading( false );
					}
				}}>
				{loading ? <Loading size={14} containerProps={{ sx: { p: 0, mt: 0 } }}/> : <LockIcon/>}
			</IconButton>
		</Tooltip>
	);
}

