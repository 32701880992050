import CloverStockMismatch from '@/components/snackbarComponents/cloverStockMismatch';
import { provider, ProviderComposer } from '@/providers';
import AuthProvider from '@/providers/auth';
import ChatProvider from '@/providers/chat';
import DropZoneProvider from '@/providers/dropzone';
import EventsProvider from '@/providers/event';
import MenuProvider from '@/providers/menu';
import ModalProvider from '@/providers/modal';
import PopperProvider from '@/providers/popper';
import { persistor, store } from '@/store';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { enUS, esES, frFR, LocalizationProvider, zhHK } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LoadScript } from '@react-google-maps/api';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { Fragment, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import suppressConsoleWarnings, { warningsToSuppress } from '../helpers/suppressConsoleWarnings';
import useGetDeviceInfo from '../hooks/useGetDeviceInfo';

type Libraries = [ 'core' | 'maps' | 'places' | 'geocoding' | 'routes' | 'marker' | 'geometry' | 'elevation' | 'streetView' | 'journeySharing' | 'drawing' ];

suppressConsoleWarnings( warningsToSuppress );

function getMuiLocale( i18nLocale: string ) {
	const localeMap = {
		en: enUS,
		fr: frFR,
		es: esES,
		cn: zhHK,
	};
	const muiLocale = localeMap[ i18nLocale ] || enUS;
	return muiLocale?.components?.MuiLocalizationProvider?.defaultProps?.localeText || {};
}

export default function Providers( {
	pageProps,
	children,
}: {
	pageProps: { statusCode: number, dehydratedState?: any },
	children?: ReactNode
} ) {
	const { i18n } = useTranslation();
	const notistackRef = useRef<SnackbarProvider | null>( null );
	const { isCloverDevice } = useGetDeviceInfo();
	
	const [ libraries ] = useState<Libraries>( [ 'places' ] );
	
	const [ queryClient ] = useState( () => new QueryClient( {
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				// staleTime           : 60 * 1000
			},
		},
	} ) );
	
	return (
		<ProviderComposer
			providers={[
				// data
				!isCloverDevice ? provider( LoadScript, {
					id              : 'script-loader',
					googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_KEY!,
					libraries,
					 
					loadingElement: <Fragment/>,
				} ) : undefined,
				provider( StoreProvider, { store } ),
				provider( PersistGate, { loading: null, persistor } ),
				provider( EventsProvider ),
				provider( QueryClientProvider, { client: queryClient } ),
				provider( Hydrate, { state: pageProps.dehydratedState } ),
				provider( LocalizationProvider, {
					dateAdapter: AdapterDateFns,
					localeText : getMuiLocale( i18n.language ),
				} ),
				// static
				provider( SnackbarProvider, {
					// @ts-ignore
					Components      : { CloverStockMismatch },
					ref             : notistackRef,
					preventDuplicate: true,
					style           : { fontSize: '1.4rem' },
					action          : ( key ) => (
						<IconButton color='inherit' onClick={() => notistackRef.current?.closeSnackbar( key )}>
							<CloseIcon/>
						</IconButton>
					),
				} ),
				provider( AuthProvider ),
				provider( ChatProvider ),
				provider( DropZoneProvider ),
				// dynamic
				provider( PopperProvider ),
				provider( MenuProvider ),
				provider( ModalProvider ),
			].filter( Boolean )}>
			{children}
		</ProviderComposer>
	);
}
