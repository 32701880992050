import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { CommerceType } from '@/types/schema';
import {
	Add as AddIcon,
	SettingsSuggest as SettingsSuggestIcon,
	VisibilityRounded as VisibilityRoundedIcon,
} from '@mui/icons-material';
import { Divider, Fab, ListItemIcon, MenuItem, MenuList, styled, Zoom } from '@mui/material';
import { startCase, upperFirst } from 'lodash-es';
import { useRouter } from 'next/router';
import React from 'react';
import { useCreateNewEstimateWithType } from '../../commerce/estimates/actions/newEstimateTypesList';
import { useCreateNewInvoice } from '../../commerce/invoices/actions/pageWrapperActions';
import { useCreateNewOrder } from '../../commerce/orders/actions/pageWrapperActions';
import { useCreateNewPurchase } from '../../commerce/purchases/actions/pageWrapperActions';
import { ActionMappings, QuickAction } from './types';

const getEstimateTypeFilter = ( type: CommerceType ) => encodeURIComponent( Buffer.from( JSON.stringify( {
	filters: [ {
		id   : 'type',
		value: {
			$ilike: type,
			temp  : { checked: true },
		},
	} ],
} ) ).toString( 'base64' ) );

export default function QuickActionFloatingButton() {
	const router = useRouter();
	const { showMenu } = useMenu();
	const { staff } = useUserInfo();
	
	const quickActions: QuickAction[] = staff?.actionsData?.quickActions || [];
	
	const createNewInvoice = useCreateNewInvoice();
	const createNewOrder = useCreateNewOrder();
	const createPurchase = useCreateNewPurchase();
	const createNewEstimateWithType = useCreateNewEstimateWithType();
	
	const actionMappings: ActionMappings = {
		create: {
			invoice   : () => createNewInvoice(),
			order     : () => createNewOrder(),
			purchase  : () => createPurchase(),
			estimate  : () => createNewEstimateWithType( 'ESTIMATE' ),
			proposal  : () => createNewEstimateWithType( 'PROPOSAL' ),
			quote     : () => createNewEstimateWithType( 'QUOTE' ),
			work_order: () => createNewEstimateWithType( 'WORK_ORDER' ),
			bid       : () => createNewEstimateWithType( 'BID' ),
			item      : () => router.push( '/dashboard/management/items/create' ),
			client    : () => router.push( '/dashboard/management/clients/create' ),
			vendor    : () => router.push( '/dashboard/management/vendors/create' ),
			payment   : () => router.push( '/dashboard/commerce/payments/charge' ),
		},
		view: {
			invoice     : () => router.push( '/dashboard/commerce/invoices' ),
			order       : () => router.push( '/dashboard/commerce/orders' ),
			purchase    : () => router.push( '/dashboard/commerce/purchases' ),
			estimate    : () => router.push( `/dashboard/commerce/estimates?s=${getEstimateTypeFilter( 'ESTIMATE' )}` ),
			proposal    : () => router.push( `/dashboard/commerce/estimates?s=${getEstimateTypeFilter( 'PROPOSAL' )}` ),
			quote       : () => router.push( `/dashboard/commerce/estimates?s=${getEstimateTypeFilter( 'QUOTE' )}` ),
			work_order  : () => router.push( `/dashboard/commerce/estimates?s=${getEstimateTypeFilter( 'WORK_ORDER' )}` ),
			bid         : () => router.push( `/dashboard/commerce/estimates?s=${getEstimateTypeFilter( 'BID' )}` ),
			item        : () => router.push( '/dashboard/management/items' ),
			client      : () => router.push( '/dashboard/management/clients' ),
			houseAccount: () => router.push( '/dashboard/management/houseAccounts' ),
			vendor      : () => router.push( '/dashboard/management/vendors' ),
			payment     : () => router.push( '/dashboard/commerce/payments' ),
			report      : () => router.push( '/dashboard/management/reports' ),
		},
	};
	
	const handleActionClick = ( type, name, closeMenu ) => {
		closeMenu();
		if ( type === 'create' && actionMappings.create[ name ] ) {
			actionMappings.create[ name ]();
		} else if ( type === 'view' && actionMappings.view[ name ] ) {
			actionMappings.view[ name ]();
		}
	};
	
	if ( !quickActions.length ) return null;
	const StyledImage = styled( 'img' )( {} );
	
	return (
		<Zoom in>
			<Fab
				color='secondary'
				size='medium'
				sx={{
					boxShadow: 5,
					position : 'fixed',
					zIndex   : 1000,
					bottom   : 'calc(env(safe-area-inset-bottom) + 64px)',
					right    : 'calc(env(safe-area-inset-right) + 24px)',
					mb       : { xs: 8, sm: 0 },
				}}
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<MenuList>
						{quickActions?.map( ( action, index ) => (
							<MenuItem
								key={index}
								onClick={() => handleActionClick( action.type, action.name, closeMenu )}>
								<ListItemIcon>
									{action.type === 'create' ? <AddIcon/> : <VisibilityRoundedIcon/>}
								</ListItemIcon>
								{startCase( upperFirst( `${action.type} ${action.name}` ) )}{action.type === 'view' && 's'}
							</MenuItem>
						) )}
						<Divider/>
						<MenuItem
							onClick={() => {
								router.push( '/dashboard/settings/preferences' ).then();
								closeMenu();
							}}>
							<ListItemIcon sx={{ width: 30 }}>
								<SettingsSuggestIcon/>
							</ListItemIcon>
							Modify Actions
						</MenuItem>
					</MenuList>
				), e.currentTarget )}>
				<StyledImage
					alt='fb-pixel'
					height='30px'
					width='30px'
					src='/images/invoissfeathericon-white.png'
				/>
			</Fab>
		</Zoom>
	);
}

