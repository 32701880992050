import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import CardConnectFormModal from '@/pages/dashboard/settings/gateway/cardConnectForm';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import { Button } from '@mui/material';
import { useAtom } from 'jotai';
import { lowerCase } from 'lodash-es';
import { useRouter } from 'next/router';
import { Banner } from '.';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';

export default function useCardConnectBanner(): Banner {
	const { company } = useCompany();
	const { showModal } = useModal();
	const router = useRouter();
	const { staff } = useUserInfo();
	const isPublic = router.route.split( '/' )[ 1 ] === 'p';
	const isClient = router.route.split( '/' )[ 1 ] === 'client';
	
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	
	const urlParam = new URLSearchParams( router.asPath.split( '?' )[ 1 ] );
	const devFlag = urlParam.has( 'cardConnectBanner' );
	const hasCardConnect = company?.gateways?.some( ( g ) => g.external === 'CARD_CONNECT' );
	const hasUsdGateway = company?.gateways?.some( ( g ) => g.currency === 'USD' );
	const hasAchGateway = company?.gateways?.some( ( gateway ) => gateway.paymentMethod?.includes( 'ACH' ) );
	
	const hasUsAddress = company?.locations
		?.map( ( l ) => l.address )
		?.some( ( addr ) => [ 'united states', 'us', 'usa' ].includes( lowerCase( addr.country ) ) );
		
	return {
		id      : 'cardConnectBanner',
		color   : 'primary',
		show    : staff && !isPublic && !isClient && Boolean( devFlag || !hasAchGateway && !hasCardConnect && ( hasUsAddress || hasUsdGateway ) ) && !globalBanners.hideCardConnectBanner,
		icon    : <AccountBalanceRoundedIcon/>,
		title   : 'Save money!',
		subtitle: 'Enable bank transfer (ACH) payments for your business! Let your customers pay via bank account.',
		onClose : () => setGlobalBanners( ( prev ) => ( { ...prev, hideCardConnectBanner: true } ) ),
		actions : [
			<Button
				key='connect'
				variant='contained'
				color='primary'
				onClick={() => {
					showModal( CardConnectFormModal, {
						onClose      : ( event, reason ) => stayOpen( event, reason ),
						maxWidth     : 'xs',
						fullPageModal: true,
						sx           : {
							'.MuiDialog-paper': {
								overflow: 'hidden !important',
							},
						},
					}, {
						onSubmit: () => {
							console.log( 'submitted' );
							router.push( '/dashboard/settings/gateway?tab=connected-gateways' );
						},
					} );
				}}>
				Connect Bank
			</Button>,
		],
	};
	
}
