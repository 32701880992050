import { useCompany } from '@/hooks/useSetCompanyInAtom';
import ConnectVoPayFormModal from '@/modals/vopayForm/connectVopayFormModal';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { Button } from '@mui/material';
import { useAtom } from 'jotai';
import { lowerCase } from 'lodash-es';
import { useRouter } from 'next/router';
import { Banner } from '.';
import useUserInfo from '@/providers/auth/useUserInfo';

export default function useVopayBanner(): Banner {
	const { company } = useCompany();
	const { showModal } = useModal();
	const router = useRouter();
	const { staff } = useUserInfo();
	const isPublic = router.route.split( '/' )[ 1 ] === 'p';
	const isClient = router.route.split( '/' )[ 1 ] === 'client';
	
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	
	const urlParam = new URLSearchParams( router.asPath.split( '?' )[ 1 ] );
	const devFlag = urlParam.has( 'vopayBanner' );
	const hasVoPay = company?.gateways?.some( ( g ) => g.external === 'VOPAY' );
	const hasCaAddress = Boolean( company?.locations
		?.map( ( l ) => l.address )
		?.some( ( addr ) => [ 'canada', 'ca' ].includes( lowerCase( addr.country ) ) ) );
		
	return {
		id      : 'vopay-banner',
		show    : staff && !isPublic && !isClient && ( devFlag || !hasVoPay && hasCaAddress ) && !globalBanners.hideVoPayBanner,
		title   : 'Bank transfer is now available!',
		subtitle: 'Enable your customers to pay via EFT.',
		color   : 'primary',
		onClose : () => setGlobalBanners( ( prev ) => ( { ...prev, hideVoPayBanner: true } ) ),
		actions : [
			<Button
				key='connect'
				variant='contained'
				color='primary'
				onClick={() => {
					showModal( ConnectVoPayFormModal, {
						id      : 'vopay-connect-modal',
						onClose : ( event, reason ) => stayOpen( event, reason ),
						maxWidth: 'xs',
					} );
				}}>
				Connect Bank
			</Button>,
		],
	};
}

