import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import RegisterAppModal from '@/modals/registerApp';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import { Button } from '@mui/material';
import { useAtom } from 'jotai/index';
import { Banner } from '.';

export default function useCloverDeviceBanner(): Banner {
	const { isCloverDevice } = useGetDeviceInfo();
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	const { showModal } = useModal();
	
	return {
		id      : 'clover-device-banner',
		show    : !globalBanners.hideCloverDeviceBanner && isCloverDevice,
		title   : 'Are you using a Clover device?',
		subtitle: 'Use your Clover device to create invoices, estimates much faster',
		color   : 'warning',
		onClose : () => setGlobalBanners( ( prev ) => ( { ...prev, hideCloverDeviceBanner: true } ) ),
		actions : [
			<Button
				key='learn-more'
				variant='contained'
				color='primary'
				sx={{ ml: 1 }}
				onClick={() => showModal( RegisterAppModal, { maxWidth: 'sm' } )}>
				Learn more
			</Button>,
		],
	};
}

