import WidgetBannerModal from '@/modals/widgetCreatorModal';
import { WebsiteDialog } from '@/modals/widgetDialog';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import { Code as CodeIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { Banner } from '.';
import { useRouter } from 'next/router';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
export default function useWidgetBanner(): Banner {
	const { staff } = useUserInfo();
	const { showModal } = useModal();
	const router = useRouter();
	const isPublic = router.route.split( '/' )[ 1 ] === 'p';
	const isClient = router.route.split( '/' )[ 1 ] === 'client';
	
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	
	return {
		id      : 'widget-banner',
		color   : 'primary',
		show    : staff && !isPublic && !isClient && !globalBanners.hideWidgetBanner,
		title   : 'Add Invoiss to your website',
		icon    : <WidgetsRoundedIcon/>,
		onClose : () => setGlobalBanners( ( prev ) => ( { ...prev, hideWidgetBanner: true } ) ),
		subtitle: (
			<Typography color='text.secondary'>
				Add Invoiss to {staff?.company?.website || 'your website'} with a full client portal, online store and
				payment link.
			</Typography>
		),
		actions: [
			staff?.company?.website && (
				<Button
					key='preview'
					variant='outlined'
					color='secondary'
					sx={{ textTransform: 'lowercase' }}
					onClick={() => showModal( WebsiteDialog, { variant: 'fullPageDialog' }, undefined )}>
					Preview on {staff?.company?.website}
				</Button>
			),
			<Button
				key='create-widget'
				variant='contained'
				color='primary'
				onClick={() => showModal( WidgetBannerModal, { maxWidth: 'md' }, undefined )}>
				Create widget
			</Button>,
		],
	};

}

