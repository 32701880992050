import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { auth } from '@/firebase/client';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { LogoutRounded as LogoutIcon, MailOutlineRounded as MailIcon, PrivacyTipRounded as PrivacyTipRoundedIcon } from '@mui/icons-material';
import { AppBar, Box, Container, Divider, Grow, ListItem, ListItemIcon, ListItemText, Paper, Stack, Theme, Toolbar, Typography, useMediaQuery, useScrollTrigger } from '@mui/material';
import axios from 'axios';
import { addDays, differenceInDays, startOfDay } from 'date-fns';
import { signOut } from 'firebase/auth';
import { useSnackbar } from 'notistack';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

// Configuration for email verification periods
const STANDARD_VERIFICATION_DAYS = 5;
const EXTENDED_VERIFICATION_DAYS = 10;
const CUTOFF_DATE = startOfDay( new Date( '2025-02-21' ) ); // Start of today

export default function EmailVerificationWraper( { children }: { children: ReactNode } ) {
	const [ user, loading ] = useAuthState( auth );
	const { enqueueSnackbar } = useSnackbar();
	const { isCloverDevice } = useGetDeviceInfo();
	const [ daysLeft, setDaysLeft ] = useState<number | null>( null );
	const [ daysUnverified, setDaysUnverified ] = useState<number | null>( null );
	const [ isBlocked, setIsBlocked ] = useState( false );
	const appBarRef = useRef<HTMLDivElement | null>( null );
	const [ isOlderAccount, setIsOlderAccount ] = useState( false );
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'md' ) );
	
	const trigger = useScrollTrigger( { disableHysteresis: true, threshold: 0 } );
	
	useEffect( () => {
		if ( user?.metadata.creationTime ) {
			const creationDate = startOfDay( new Date( user.metadata.creationTime ) );
			const now = startOfDay( new Date() );
         
			// Calculate how long the user has been unverified
			const unverifiedDays = differenceInDays( now, creationDate );
         
			/*
          * 🧪 TESTING VERIFICATION PERIODS:
          *
          * To test standard 5-day verification (new accounts):
          * const unverifiedDays = 6; // This will trigger blocking
          * const unverifiedDays = 4; // This will show 1 day remaining
          *
          * To test extended verification (old accounts):
          * 1. Make sure account creation date is before Feb 20, 2025
          * 2. To test blocking: change CUTOFF_DATE to a date more than 10 days ago
          */
         
			setDaysUnverified( unverifiedDays );

			// Check if this is an older account (created before cutoff)
			const isOlder = creationDate < CUTOFF_DATE;
			setIsOlderAccount( isOlder );
         
			if ( isOlder ) {
				// For older accounts, check if they're within their 10-day grace period
				const gracePeriodEnd = addDays( CUTOFF_DATE, EXTENDED_VERIFICATION_DAYS );
				const daysUntilGraceEnds = differenceInDays( gracePeriodEnd, now );
            
				setDaysLeft( Math.max( 0, daysUntilGraceEnds ) );
				setIsBlocked( daysUntilGraceEnds <= 0 ); // Block if grace period is over
			} else {
				// New accounts get 5 days to verify
				const daysRemaining = STANDARD_VERIFICATION_DAYS - unverifiedDays;
				setDaysLeft( Math.max( 0, daysRemaining ) );
				setIsBlocked( daysRemaining <= 0 );
			}
		}
	}, [ user ] );

	const handleCheckVerification = async () => {
		try {
			await user?.reload();
			window.location.reload();
		} catch ( error ) {
			console.error( 'Error checking verification:', error );
			enqueueSnackbar( 'Error checking verification status', { variant: 'error' } );
		}
	};

	const handleResendVerification = async () => {
		try {
			if ( user ) {
				await axios.post( '/api/emails/confirmEmail', {
					email    : user.email,
					firstName: user.displayName?.split( ' ' )[ 0 ] || '',
				} );
				enqueueSnackbar( 'Email Verification Sent', { variant: 'success' } );
			}
		} catch ( error ) {
			console.error( 'Error sending verification email:', error );
			enqueueSnackbar( 'Error sending verification email', { variant: 'error' } );
		}
	};

	const handleLogout = async () => {
		try {
			await signOut( auth );
			enqueueSnackbar( 'Logged out successfully', { variant: 'success' } );
		} catch ( error ) {
			console.error( 'Error logging out:', error );
			enqueueSnackbar( 'Error logging out', { variant: 'error' } );
		}
	};
	
	// Return children directly for Clover devices after all states and effects
	if ( isCloverDevice ) return children;
	
	// Return null during initial load or while calculating days
	if ( loading || daysLeft === null ) return null;

	if ( !user || user.emailVerified ) return children;

	if ( isBlocked ) {
		return (
			<Grow in>
				<Box
					sx={{
						position      : 'fixed',
						top           : 0,
						left          : 0,
						right         : 0,
						bottom        : 0,
						display       : 'flex',
						alignItems    : 'center',
						justifyContent: 'center',
						bgcolor       : 'rgba(0, 0, 0, 0.05)',
						zIndex        : 1200,
						p             : 3,
					}}>
					<Paper
						sx={{
							maxWidth    : 500,
							width       : '100%',
							overflow    : 'hidden',
							borderRadius: 2,
						}}>
						<Box
							sx={{
								bgcolor  : 'alpha.warning',
								py       : 4,
								px       : 3,
								textAlign: 'center',
								color    : 'warning.main',
							}}>
							<MailIcon sx={{ fontSize: 48, mb: 2 }}/>
							<Typography gutterBottom variant='h5' fontWeight={600}>
								Email Verification Required
							</Typography>
							<Typography variant='body1'>
								Your verification window has expired
							</Typography>
						</Box>
						<Box sx={{ p: 4 }}>
							<Typography
								variant='body1'
								sx={{
									textAlign: 'center',
									color    : 'text.secondary',
									mb       : 3,
								}}>
								To continue using your account, please verify your email address:
								<Typography
									component='div'
									sx={{
										color     : 'text.primary',
										fontWeight: 500,
										mt        : 1,
									}}>
									{user.email}
								</Typography>
							</Typography>
							<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='center'>
								<AsyncLoadingButton
									variant='outlined'
									color='warning'
									size='large'
									sx={{ minWidth: 180, borderRadius: 2 }}
									onClick={handleCheckVerification}>
									Check Verification
								</AsyncLoadingButton>
								<AsyncLoadingButton
									variant='contained'
									color='warning'
									size='large'
									sx={{
										minWidth    : 180,
										borderRadius: 2,
									}}
									onClick={handleResendVerification}>
									Resend Email
								</AsyncLoadingButton>
							</Stack>
							<Divider sx={{ mt: 4, mb: 3 }}/>
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<AsyncLoadingButton
									variant='text'
									color='error'
									size='large'
									startIcon={<LogoutIcon/>}
									onClick={handleLogout}>
									Logout
								</AsyncLoadingButton>
							</Box>
						</Box>
					</Paper>
				</Box>
			</Grow>
		);
	}

	return (
		<Fragment>
			<AppBar
				ref={appBarRef}
				color='inherit'
				sx={{
					'boxShadow'      : 0,
					'backgroundColor': 'background.default',
					'border'         : 0,
					'position'       : { xs: 'static', sm: 'fixed' },
					'overflow'       : 'hidden',
					'&::after'       : {
						content   : '""',
						position  : 'absolute',
						bottom    : 0,
						left      : 0,
						right     : 0,
						height    : '1px',
						bgcolor   : 'warning.main',
						opacity   : trigger ? 1 : 0,
						transition: 'opacity 0.2s ease-in-out',
					},
				}}>
				<Toolbar
					sx={{
						bgcolor    : 'alpha.warning',
						borderColor: 'warning.main',
						width      : '100%',
					}}>
					<Container
						maxWidth='xl'
						sx={{
							pb: { xs: 2, sm: 0 },
						}}>
						<Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center' spacing={2} justifyContent='center'>
							<ListItem disableGutters>
								<ListItemIcon sx={{ minWidth: 35 }}>
									<PrivacyTipRoundedIcon color='warning'/>
								</ListItemIcon>
								<ListItemText
									primary={(
										<Fragment>
											Verify Your Email <Typography component='span' sx={{ fontWeight: 600 }}>{user?.email}</Typography>
										</Fragment>
									)}
									secondary={(
										<Fragment>
											{isOlderAccount ? (
												<Fragment>
													Your account has been unverified for <Typography component='span' sx={{ color: 'warning.main', fontWeight: 500 }}>{daysUnverified}</Typography> days.{' '}
													You have <Typography component='span' sx={{ color: 'warning.main', fontWeight: 500 }}>{daysLeft}</Typography> days remaining to verify your email.
												</Fragment>
											) : (
												<Fragment>
													Please verify your email within the next <Typography component='span' sx={{ color: 'warning.main', fontWeight: 500 }}>{daysLeft}</Typography> days to keep your account active.
												</Fragment>
											)}
										</Fragment>
									)}
									sx={{ color: 'warning.main' }}
									primaryTypographyProps={{ fontWeight: 500 }}
								/>
							</ListItem>
							<Stack direction='row' spacing={2} justifyContent='center'>
								<AsyncLoadingButton
									variant='text'
									color='warning'
									size='small'
									sx={{ ml: 2 }}
									onClick={handleCheckVerification}>
									Check Verification
								</AsyncLoadingButton>
								<AsyncLoadingButton
									variant='contained'
									color='warning'
									size='small'
									sx={{ ml: 2 }}
									onClick={handleResendVerification}>
									Resend Verification Email
								</AsyncLoadingButton>
							</Stack>
						</Stack>
						
					</Container>
				</Toolbar>
			</AppBar>
			{!isMobile && <Box sx={{ height: appBarRef.current?.clientHeight }}/>}
			{!isBlocked && children}
		</Fragment>
	);
}
