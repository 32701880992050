import PageLinkComponent from '@/components/page/linkComponent';
import { TabRounded as TabRoundedIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useRouter } from 'next/router';

export default function IframeButton() {
	const router = useRouter();
	const isIframeRoute = router.asPath.split( '/' )[ 2 ] === 'iframeSetup';
	return (
		<Tooltip placement='bottom' title='Iframe Setup'>
			<IconButton
				component={PageLinkComponent}
				href='/partner/iframeSetup'
				color={isIframeRoute ? 'primary' : 'default'}>
				<TabRoundedIcon/>
			</IconButton>
		</Tooltip>
	);
}
