import AppLockButton from '@/baseline/navigation/desktop/appLockButton';
import PrePaidCreditTotal from '@/baseline/navigation/desktop/prePaidCredit';
import TrialChip from '@/baseline/navigation/desktop/trialChip';
import useUserInfo from '@/providers/auth/useUserInfo';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import { AppBar, Box, Container, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useWindowSize } from 'react-use';
import AdminButton, { LogOutButton, VerifyEmailButton } from './adminButton';
import AdminNotifications from './adminNotifications';
import AuthButtons from './authButtons';
import BannerStack from './banners';
import SelectCompanyMenu from './companyMenu';
import GlobalSearchButton from './globalSearchButton';
import HelpButton from './helpButton';
import LanguageButton from './languageButton';
import ProfileButton from './profileButton';
import TaskList from './tasks';
import UpdateNotification from './updateNotification';

export default function DesktopNavigation( { children } ) {
	const { user, staff } = useUserInfo();
	const { height } = useWindowSize();
	const superAdmin = isSuperAdmin( user );
	const router = useRouter();
	const isPublic = router.route.split( '/' )[ 1 ] === 'p';
	const isClient = router.route.split( '/' )[ 1 ] === 'client';
	const isWelcome = router.route === '/welcome';
	const isDriver = router.route === '/driver';
	
	return (
		<Box
			pl='env(safe-area-inset-left)'
			pr='env(safe-area-inset-right)'
			minHeight={`min(calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)), ${height}px)`}
			sx={{
				transition: ( { transitions } ) => transitions.create( 'margin-left' ),
				bgcolor   : 'background.default',
			}}>
			{superAdmin && (
				<Box
					sx={{
						bgcolor       : 'primary.main',
						display       : 'flex',
						alignItems    : 'center',
						justifyContent: 'space-between',
						p             : .5,
					}}>
					<Box/>
					<Stack direction='row' spacing={1} pr={5}>
						<AdminButton/>
						<VerifyEmailButton/>
						<LogOutButton/>
					</Stack>
				</Box>
			)}
			{/* Banner Stack */}
			<BannerStack/>
			
			{/* App Bar */}
			<AppBar
				color='inherit'
				position='static'
				sx={{
					pt         : router.route !== '/p/connect' ? 1 : 0,
					bgcolor    : 'background.default',
					boxShadow  : 'none',
					border     : 0,
					borderColor: 'divider',
				}}>
				<Container maxWidth='xl'>
					<Stack alignItems='center' direction='row' spacing={2}>
						{router.route !== '/p/connect' && (
							user ? (
								<Fragment>
									{!isWelcome && <SelectCompanyMenu/>}
									<Box display='flex' flexGrow={1}/>
									{!isPublic && !isClient && <TrialChip/>}
									{!isPublic && !isClient && <TaskList/>}
									<UpdateNotification/>
									{!isPublic
										&& !isWelcome
										&& !isDriver
										&& !isClient
										&& <AppLockButton/>}
									{!isPublic
										&& !isWelcome
										&& !isDriver
										&& !isClient
										&& <GlobalSearchButton/>}
									{!isPublic
										&& !isWelcome
										&& !isDriver
										&& !isClient
										&& <AdminNotifications/>}
									{isClient && <PrePaidCreditTotal/>}
									<HelpButton/>
									<LanguageButton/>
									<ProfileButton/>
								</Fragment>
							) : <AuthButtons/>
						)}
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Box>
	);
}
