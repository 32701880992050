import { useGraphQL } from '@/data';
import { HouseAccountExists } from '@/data/management/houseAccount.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { type QueryHouseAccountExistsArgs } from '@/types/schema';
import { ClientEmployeeCards } from '../../data/management/employee.graphql';

export default function EmployeeHasCards(): boolean {
	const { user } = useUserInfo();
	const { data } = useGraphQL( {
		queryKey: [ 'clientEmployeeCards' ],
		query   : ClientEmployeeCards,
	}, { enabled: Boolean( user?.email ) } );
	
	return ( data?.clientEmployeeCards?.count || 0 ) > 0;
}

export function ClientHasHouseAccount(): boolean {
	const { user } = useUserInfo();
	const { data } = useGraphQL<QueryHouseAccountExistsArgs>( {
		query    : HouseAccountExists,
		queryKey : 'houseAccountExists',
		variables: {
			options: {
				limit: 1,
			},
		},
	}, { enabled: Boolean( user?.email ) } );
	
	return ( data?.houseAccountExists?.items?.length || 0 ) > 0;
}
